import { vistaI18nRoutesBy99dLocale } from './vistaI18nRoutesBy99dLocale';

const vistaprintLabelUrls: { [key: string]: string } = {
    'da-dk': '/etiketter-klistermaerker/specialdesignede-etiketter',
    'de-at': '/etiketten-und-aufkleber/personalisierte-etiketten',
    'de-ch': '/etiketten-und-aufkleber/personalisierte-etiketten',
    'de-de': '/etiketten-und-aufkleber/personalisierte-etiketten',
    'en-at': '/etiketten-und-aufkleber/personalisierte-etiketten',
    'en-au': '/labels-stickers/labels-tags',
    'en-ca': '/labels-stickers',
    'en-dk': '/etiketter-klistermaerker/specialdesignede-etiketter',
    'en-gb': '/labels-stickers/labels',
    'en-ie': '/labels-stickers',
    'en-in': '/marketing-materials/labels-stickers',
    'en-nz': '/labels-stickers',
    'en-sg': '/labels-stickers/labels',
    'en-us': '/labels-stickers/labels',
    'es-es': '/etiquetas-y-pegatinas/etiquetas',
    'es-us': '/etiquetas-adhesivos/etiquetas',
    'fi-fi': '/tarrat-etiketit/yksilolliset-etiketit',
    'fr-be': '/etiquettes-autocollants/etiquettes-personnalisees',
    'fr-ca': '/etiquettes-autocollants/etiquettes-personnalises',
    'fr-ch': '/etiquettes-autocollants/etiquettes-personnalisees',
    'fr-fr': '/etiquettes-autocollants/etiquettes-personnalisees',
    'it-ch': '/etichette-adesivi/etichette',
    'it-it': '/etichette-adesivi/etichette',
    'ja-jp': '/labels-stickers/labels',
    'nl-be': '/labels-stickers/gepersonaliseerde-etiketten',
    'nl-nl': '/labels-stickers/gepersonaliseerde-etiketten',
    'no-no': '/klistremerker-etiketter/etichette',
    'pt-pt': '/etiquetas-e-autocolantes/etiquetas',
    'sv-se': '/klistremerker-etiketter/personliga-etiketter',
};

const vistaprintPostcardUrls: { [key: string]: string } = {
    'da-dk': '/markedsfoerings-materialer/tilpassede-flyers',
    'de-at': '/marketingmaterial/benutzerdefinierte-flugblaetter',
    'de-ch': '/marketingmaterial/benutzerdefinierte-flugblaetter',
    'de-de': '/marketingmaterial/benutzerdefinierte-flugblaetter',
    'en-at': '/marketingmaterial/benutzerdefinierte-flugblaetter',
    'en-au': '/marketing-materials/postcards',
    'en-ca': '/marketing-materials/postcards',
    'en-dk': '/markedsfoerings-materialer/tilpassede-flyers',
    'en-gb': '/marketing-materials/postcards-marketing-handouts',
    'en-ie': '/marketing-materials/custom-handouts',
    'en-in': '/marketing-materials/postcards',
    'en-nz': '/marketing-materials/postcards',
    'en-sg': '/marketing-materials/postcards',
    'en-us': '/marketing-materials/postcards',
    'es-es': '/materiales-de-marketing/folletos-personalizados',
    'es-us': '/materiales-de-mercadeo/postales',
    'fi-fi': '/markkinointimateriaalit/postikortit',
    'fr-be': '/supports-marketing/documents-a-distribuer-personnalises',
    'fr-ca': '/supports-marketing/cartes-postales',
    'fr-ch': '/supports-marketing/documents-a-distribuer-personnalises',
    'fr-fr': '/supports-marketing/documents-a-distribuer-personnalises',
    'it-ch': '/prodotti-marketing/articoli-cartacei-da-distribuire-personalizzati',
    'it-it': '/prodotti-marketing/articoli-cartacei-da-distribuire-personalizzati',
    'ja-jp': '/marketing-materials/postcards',
    'nl-be': '/marketingmateriaal/bedrukte-hand-outs',
    'nl-nl': '/marketingmateriaal/bedrukte-hand-outs',
    'no-no': '/markedsfoeringsmateriale/egne-postkort-med-logo',
    'pt-pt': '/materiais-de-marketing/materiais-publicitarios-personalizados',
    'sv-se': '/reklammaterial/specialdesignade-reklamblad',
};

const vistaprintTshirtUrls: { [key: string]: string } = {
    'da-dk': '/beklaedning-tasker/t-shirts',
    'de-at': '/kleidung-taschen/t-shirts',
    'de-ch': '/kleidung-taschen/t-shirts',
    'de-de': '/kleidung-taschen/t-shirts',
    'en-at': '/kleidung-taschen/t-shirts',
    'en-au': '/clothing-bags/t-shirts',
    'en-ca': '/clothing-bags/t-shirts',
    'en-dk': '/beklaedning-tasker/t-shirts',
    'en-gb': '/clothing-bags/t-shirts',
    'en-ie': '/clothing-bags/t-shirts',
    'en-in': '/clothing-bags',
    'en-nz': '/clothing-bags/t-shirts',
    'en-sg': '/clothing-bags/t-shirts',
    'en-us': '/clothing-bags/t-shirts',
    'es-es': '/ropa-y-bolsas/camisetas',
    'es-us': '/ropa-bolsas/camisetas',
    'fi-fi': '/vaatteet-kassit/t-paidat/kaikki-T-paidat',
    'fr-be': '/vetements-sacs/t-shirts',
    'fr-ca': '/vetements-sacs/t-shirts',
    'fr-ch': '/vetements-sacs/t-shirts',
    'fr-fr': '/vetements-sacs/t-shirts',
    'it-ch': '/abbigliamento-borse/magliette',
    'it-it': '/abbigliamento-borse/magliette',
    'ja-jp': '/clothing-bags/t-shirts',
    'nl-be': '/kleding-tassen/t-shirts',
    'nl-nl': '/kleding-tassen/t-shirts',
    'no-no': '/klaer-vesker/t-skjorter/alle-t-skjorter',
    'pt-pt': '/vestuario-e-sacos/t-shirts/tudo-t-shirts',
    'sv-se': '/klader-och-vaskor/t-shirts',
};

const fallbackLocaleLabelUrl = vistaprintLabelUrls['en-us'];
const fallbackLocalePostcardUrl = vistaprintPostcardUrls['en-us'];
const fallbackLocaleTshirtUrl = vistaprintTshirtUrls['en-us'];

const categoryUrlsMap: { [key: string]: { [key: string]: string } } = {
    label: vistaprintLabelUrls,
    postcard: vistaprintPostcardUrls,
    tshirt: vistaprintTshirtUrls,
};

const fallbackCategoryUrlsMap: { [key: string]: string } = {
    label: fallbackLocaleLabelUrl,
    postcard: fallbackLocalePostcardUrl,
    tshirt: fallbackLocaleTshirtUrl,
};

// Usages:
//  vistaI18nRoutesBy99dLocaleAndCategory("en-US", "label")
//  vistaI18nRoutesBy99dLocaleAndCategory("en-us", "postcard")
//  vistaI18nRoutesBy99dLocaleAndCategory("de-at", "tshirt")

export function vistaI18nRoutesBy99dLocaleAndCategory(
    locale: string,
    category: 'label' | 'postcard' | 'tshirt'
): string {
    const categoryUrls = categoryUrlsMap[category];
    const fallbackUrl = fallbackCategoryUrlsMap[category];
    const cleanedUpLocale = locale.replace('_', '-').toLowerCase();

    return `${vistaI18nRoutesBy99dLocale(cleanedUpLocale)}${
        categoryUrls[cleanedUpLocale] || fallbackUrl
    }`;
}
