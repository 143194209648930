const vistaprintLocaleBaseUrls: { [key: string]: string } = {
    'da-dk': 'https://www.vistaprint.dk',
    'de-at': 'https://www.vistaprint.at',
    'de-ch': 'https://www.vistaprint.ch',
    'de-de': 'https://www.vistaprint.de',
    'en-at': 'https://www.vistaprint.at',
    'en-au': 'https://www.vistaprint.com.au',
    'en-ca': 'https://www.vistaprint.ca',
    'en-dk': 'https://www.vistaprint.dk',
    'en-gb': 'https://www.vistaprint.co.uk',
    'en-ie': 'https://www.vistaprint.ie',
    'en-in': 'https://www.vistaprint.in',
    'en-nz': 'https://www.vistaprint.co.nz',
    'en-sg': 'https://www.vistaprint.sg',
    'en-us': 'https://www.vistaprint.com',
    'es-es': 'https://vistaprint.es',
    'es-us': 'https://www.vistaprint.com/es',
    'fi-fi': 'https://www.vistaprint.fi',
    'fr-be': 'https://www.vistaprint.be/fr',
    'fr-ca': 'https://www.vistaprint.ca/fr',
    'fr-ch': 'https://www.vistaprint.ch/fr',
    'fr-fr': 'https://www.vistaprint.fr',
    'it-ch': 'https://www.vistaprint.ch/it',
    'it-it': 'https://www.vistaprint.it',
    'ja-jp': 'https://www.vistaprint.com',
    'nl-be': 'https://www.vistaprint.be',
    'nl-nl': 'https://www.vistaprint.nl',
    'no-no': 'https://www.vistaprint.no',
    'pt-pt': 'https://www.vistaprint.pt',
    'sv-se': 'https://www.vistaprint.se',
};

const fallbackLocaleBaseUrl = vistaprintLocaleBaseUrls['en-us'];

// Usages:
//  vistaprintLocaleBaseUrls("en-US", "/terms-and-conditions")
//  vistaprintLocaleBaseUrls("en-us", "/terms-and-conditions")
//  vistaprintLocaleBaseUrls("en_us", "/terms-and-conditions")

export function vistaI18nRoutesBy99dLocale(locale: string, path = ''): string {
    const baseUrl =
        vistaprintLocaleBaseUrls[locale.replace('_', '-').toLowerCase()] || fallbackLocaleBaseUrl;

    return `${baseUrl}${path}`;
}
